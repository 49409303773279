import React from "react";
import AnalogClock from "./AnalogClock";

const Clock = () => {
  return (
    <div className="flex mt-3 justify-center">
      <div className="clock-container">
        <AnalogClock city="Asia/Dhaka" />
        <p style={{ margin: 0, marginTop: 0 }} className="text-[var(--color-white)] font-medium text-sm">
          Dhaka
        </p>
      </div>
      <div className="clock-container">
        <AnalogClock city="Asia/Dubai" />
        <p style={{ margin: 0, marginTop: 0 }} className="text-[var(--color-white)] font-medium text-sm">
          Dubai
        </p>
      </div>
      <div className="clock-container">
        <AnalogClock city="Europe/London" />
        <p style={{ margin: 0, marginTop: 0 }} className="text-[var(--color-white)] font-medium text-sm">
          London
        </p>
      </div>
      <div className="clock-container">
        <AnalogClock city="Asia/Singapore" />
        <p style={{ margin: 0, marginTop: 0 }} className="text-[var(--color-white)] font-medium text-sm">
          Singapore
        </p>
      </div>
    </div>
  );
};
export default Clock;
