import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";

const AnalogClock = ({ city }) => {
  const [time, setTime] = useState(DateTime.local().setZone(city));

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(DateTime.local().setZone(city));
    }, 1000);

    return () => clearInterval(interval);
  }, [city]);

  const getRotation = (unit, totalUnits) => {
    const degreesPerUnit = 360 / totalUnits;
    return unit * degreesPerUnit;
  };

  const hoursRotation = getRotation(time.hour % 12, 12) + getRotation(time.minute / 60, 12);
  const minutesRotation = getRotation(time.minute, 60) + getRotation(time.second / 60, 60);
  const secondsRotation = getRotation(time.second, 60);

  return (
    <div className="analog-clock">
      <div className="clock-face">
        <div className="hand hour-hand" style={{ transform: `rotate(${hoursRotation}deg)` }}></div>
        <div className="hand minute-hand" style={{ transform: `rotate(${minutesRotation}deg)` }}></div>
        <div className="hand second-hand" style={{ transform: `rotate(${secondsRotation}deg)` }}></div>
      </div>
      <div className="digital-time">
        <p style={{margin: 0, marginTop: 10}} className="text-[var(--color-white)] font-medium text-xs">{time.toFormat("HH:mm:ss")}</p>
      </div>
    </div>
  );
};

export default AnalogClock;
