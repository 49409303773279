import {createSlice} from '@reduxjs/toolkit';

const newsSlice = createSlice({
  name: 'news',
  initialState: {
    categories: [],
    initialNews: [],
  },
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setInitialNews: (state, action) => {
      state.initialNews = action.payload;
    },
  },
});

export const { setCategories, setInitialNews } = newsSlice.actions;

export default newsSlice.reducer;
