import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    themeMode: 'dark',
};

const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        setThemeMode: (state, action) => {
            state.themeMode = action.payload;
        },
    },
});

export const { setThemeMode } = applicationSlice.actions;

export default applicationSlice.reducer;
