import {createSlice} from '@reduxjs/toolkit';
import { reduxFilter, reduxMap } from 'helpers/dataHelper';

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    history: [],
    positions: [],
    pendingOrders: [],
  },
  reducers: {
    setHistory: (state, action) => {
      state.history = action.payload;
    },
    setPositions: (state, action) => {
      state.positions = action.payload;
    },
    setPendingOrders: (state, action) => {
      state.pendingOrders = action.payload;
    },
    orderPumingClose: (state, action) => {
      const fPostitions = reduxFilter(
        state.positions,
        i => i._id !== action.payload._id,
      );
      state.positions = fPostitions;
      state.history.unshift(action.payload);
    },
    orderPumingAdd: (state, action) => {
      const fPendingOrders = reduxFilter(
        state.pendingOrders,
        i => i._id !== action.payload._id,
      );
      state.pendingOrders = fPendingOrders;
      state.positions.unshift(action.payload);
    },
    pendingOrderPumingAdd: (state, action) => {
      state.pendingOrders.unshift(action.payload);
    },
    orderPumingUpdate: (state, action) => {
      const mPositions = reduxMap(state.positions, i =>
        i._id === action.payload._id ? action.payload : i,
      );
      state.positions = mPositions;
    },
  },
});

export const {
  setHistory,
  setPositions,
  setPendingOrders,
  orderPumingClose,
  orderPumingAdd,
  orderPumingUpdate,
  pendingOrderPumingAdd,
} = orderSlice.actions;

export default orderSlice.reducer;