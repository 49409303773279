import React, { memo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import constraints from "constraints/constraints";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import { darkTheme, lightTheme } from "theme";
import LockScreen from "components/Authentication/lockScreen/LockScreen";
import AccountDeactivate from "pages/AccountDeactivate";
const Auth = React.lazy(() => import("pages/auth/Auth"));
const About = React.lazy(() => import("pages/About"));
const Dashboard = React.lazy(() => import("pages/protected/Dashboard"));
const Loader = React.lazy(() => import("components/loader/Loader"));
const NotFound = React.lazy(() => import("pages/NotFound"));
const AuthLayout = React.lazy(() => import("layouts/AuthLayout"));
const ProtectedLayout = React.lazy(() => import("layouts/ProtectedLayout"));

const IS_ELECTRON_ROUTE = false;

function App() {
  const mode = useSelector((state) => state.application.themeMode);
  const theme = mode === "dark" ? darkTheme : lightTheme;

  useEffect(() => {
    document.title = constraints.name;
    // Add event listener to disable right-click
    const handleContextMenu = (e) => {
      e.preventDefault();
    };
    window.addEventListener("contextmenu", handleContextMenu);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  useEffect(() => {
    if (mode === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    document.body.style.backgroundColor = mode === "dark" ? "var(--color-black)" : "var(--color-main)";
  }, [mode]);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        {IS_ELECTRON_ROUTE ? (
          <React.Suspense fallback={<Loader />}>
            <SnackbarProvider maxSnack={3}>
              <HashRouter>
                <Routes>
                  <Route element={<AuthLayout />}>
                    {/* Auth route start from here. */}
                    <Route path="/auth" element={<Auth />} />
                    {/* Auth route end here. */}
                  </Route>
                  <Route element={<ProtectedLayout />}>
                    {/* Protected route start from here. */}
                    <Route path="/" element={<Dashboard />} />
                    {/* Protected route end here. */}
                  </Route>
                  {/* Other route start here. */}
                  <Route path="/about" element={<About />} />
                  <Route path="/account-deactivate" element={<AccountDeactivate />} />
                  <Route path="/lock-screen" element={<LockScreen />} />
                  {/* Other route end here. */}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </HashRouter>
            </SnackbarProvider>
          </React.Suspense>
        ) : (
          <React.Suspense fallback={<Loader />}>
            <SnackbarProvider maxSnack={3}>
              <BrowserRouter>
                <Routes>
                  <Route element={<AuthLayout />}>
                    {/* Auth route start from here. */}
                    <Route path="/auth" element={<Auth />} />
                    {/* Auth route end here. */}
                  </Route>
                  <Route element={<ProtectedLayout />}>
                    {/* Protected route start from here. */}
                    <Route path="/" element={<Dashboard />} />
                    {/* Protected route end here. */}
                  </Route>
                  {/* Other route start here. */}
                    <Route path="/about" element={<About />} />
                    <Route path="/account-deactivate" element={<AccountDeactivate />} />
                  <Route path="/lock-screen" element={<LockScreen />} />
                  {/* Other route end here. */}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </BrowserRouter>
            </SnackbarProvider>
          </React.Suspense>
        )}
      </ThemeProvider>
    </div>
  );
}

export default memo(App);
