import { createSlice } from '@reduxjs/toolkit';

const tabSlice = createSlice({
    name: 'tabChange',
    initialState: {
        tab: "chart"
    },
    reducers: {
        setTabChange: (state, action) => {
            state.tab = action.payload;
        },

    },
});

export const { setTabChange } = tabSlice.actions;

export default tabSlice.reducer;
