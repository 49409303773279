import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sidebarOpen: false,
};

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setInitialSidebar: (state) => {
            state.sidebarOpen = true;
        },
        setSidebar: (state) => {
            state.sidebarOpen = !state.sidebarOpen;
            localStorage.setItem("sidebarOpen", state.sidebarOpen);
        }
    }
});

export const { setSidebar, setInitialSidebar } = layoutSlice.actions;

export default layoutSlice.reducer;
