import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: {},
        account: null,
        loggedIn: false,
        balance: 0,
        profit: 0,
        profits: {},
        margin: 0,
        freeMargin: 0,
        marginLevel: 0,
    },
    reducers: {
        loggedIn: (state, action) => {
            state.loggedIn = true;
            state.user = action.payload;
        },
        signin: (state, action) => {
            state.loggedIn = true;
            state.user = action.payload;
            state.account = action.payload?.login;
            const json_user = JSON.stringify(action.payload);
            localStorage.setItem("user", json_user);
        },
        setInitialAuthData: (state, action) => {
            state.loggedIn = true;
            state.user = action.payload;
        },
        signOut: state => {
            localStorage.removeItem("user");
            state.loggedIn = false;
            state.user = null;
        },
        setBalance: (state, action) => {
            state.balance = action.payload;
        },
        addBalance: (state, action) => {
            state.balance += action.payload;
        },
        profitCalculation: (state, action) => {
            state.profit = action.payload;
        },
        setMargin: (state, action) => {
            state.margin = action.payload;
        },
        setFreeMargin: (state, action) => {
            state.freeMargin = action.payload?.margin;
            state.marginLevel = isFinite(action.payload?.level)
                ? action.payload?.level
                : 0;
        },
    },
});
export const { signin, signOut, setInitialAuthData, loggedIn, setBalance, addBalance, profitCalculation, setProfit, addProfits, setMargin, setFreeMargin, setMarginLevel } = authSlice.actions;
export default authSlice.reducer;