import api from "api";


export async function login({ login, password }) {
  try {
    const result = await api.post("/user/guest/login", { login, password });
    if (!result?.data?.error) {
      if (result) {
        localStorage.setItem("refresh-token",result.data.data.refreshToken);
        localStorage.setItem("auth-token", result.data.data.accessToken);
      }
    }
    return result;
  } catch (error) { }
}




export async function registration({ email, password, confirm_password }) {
  try {
    return await api.post("/auth/user", { email, password, confirm_password });
  } catch (error) { }
}


export async function logout() {
  try {
    const result = await api.post(
      "/auth/logout",
      {},
      {
        headers: {
          access_token: localStorage.getItem("auth-token"),
          refresh_token: localStorage.getItem("refresh-token"),
          Authorization: getAuthToken(),
        },
      }
    );
    localStorage.removeItem("refresh-token");
    localStorage.removeItem("auth-token");
    return result;
  } catch (error) { }
}

export const getAuthToken = () => {
  try {
    return localStorage.getItem("auth-token") ? `Bearer ${localStorage.getItem("auth-token")}` : null;
  } catch (error) { }
};

export const getRefreshToken = () => {
  try {
    return localStorage.getItem("refresh-token") ? `${localStorage.getItem("refresh-token")}` : null;
  } catch (error) { }
};

export const emailVerify = async (token, uuid) => {
  try {
    return await api.post(
      "/auth/email-verify",
      {},
      {
        headers: {
          token,
          uuid,
        },
      }
    );
  } catch (error) { }
};
export const resentEmailVerify = async (email) => {
  try {
    return await api.post("/auth/resend-verify-email", { email });
  } catch (error) { }
};
export const forgetPassword = async (email) => {
  try {
    return await api.post("/auth/forgot-password", { email });
  } catch (error) { }
};
export const resetPassword = async (password, confirm_password, uuid, token) => {
  try {
    return await api.put(
      "/auth/reset-password",
      { password, confirm_password },
      {
        headers: {
          uuid,
          token,
        },
      }
    );
  } catch (error) { }
};
export const avatarUpdate = async (file) => {
  try {
    return await api.post(
      "/account/avatar",
      { avatar: file },
      {
        headers: {
          Authorization: getAuthToken(),
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) { }
};
export const profileUpdate = async ({ first_name, last_name, address, phone_number, city, zip, country, dob }) => {
  try {
    return await api.put(
      "/account/profile",
      { first_name, last_name, address, phone_number, city, zip, country, dob },
      {
        headers: {
          Authorization: getAuthToken(),
        },
      }
    );
  } catch (error) { }
};


