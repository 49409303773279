const constraints = {
    name: 'EdgeTrader',
    buy: 0,
    sell: 1,
    buy_limit: 2,
    sell_limit: 3,
    pending_position: 0,
    open_position: 1,
    close_position: 4,
    balance: 9,
}

export default constraints;
