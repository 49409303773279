import { createSlice } from '@reduxjs/toolkit';

const chartSlice = createSlice({
    name: 'chart',
    initialState: {
        chart: null,
        chart2: null,
        chart3: null,
        chart4: null,
        selectedChart: 1,
        currentChartLayout: 1,
        test: 1,
    },
    reducers: {
        setChart: (state, action) => {
            state.chart = action.payload;
        },
        setChart2: (state, action) => {
            state.chart2 = action.payload;
        },
        setChart3: (state, action) => {
            state.chart3 = action.payload;
        },
        setChart4: (state, action) => {
            state.chart4 = action.payload;
        },
        setSelectedChart: (state, action) => {
            state.selectedChart = action.payload;
        },
        setCurrentChartLayout: (state, action) => {
            state.currentChartLayout = action.payload;
        },
        setTest: (state, action) => {
            // i want increment the value of test by 1
            state.test = state.test + 1;
        },
    },
});
export const { setChart, setChart2, setChart3, setChart4, setSelectedChart, setCurrentChartLayout, setTest } = chartSlice.actions;
export default chartSlice.reducer;