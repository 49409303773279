import { createTheme } from "@mui/material";

// for light theme
export const lightTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#000000",
            red: "#f73e4a",
            green: "#00b276",
            disable: "#e9e9e9",
            black: "#000000",
            white: "#ffffff",
        },
        background: {
            default: "#ffffff",
            secondary: "#cccccc54",
            main: "#dde2e5",
        },
        text: {
            primary: "#191919",
            secondary: "#141414",
            muted: "#606d73",
        },
        hover:{
            bgColor: "#d4d5d5", 
        },
        border: {
            primary: "#cbcbcb",
        }
        
    },
});
    
// for dark theme
export const darkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#ffffff",
            red: "#f73e4a",
            green: "#00b276",
            disable: "#000000",
            black: "#000000",
            white: "#ffffff",
        },
        background: {
            default: "#152126",
            secondary: "#1f282d",
            main: "#000000",
        },
        text: {
            primary: "#ffffff",
            secondary: "#cccccc54",
            muted: "#606d73",

        },
        hover:{
            bgColor: "#2d3940",
        },
        border: {
            primary: "#374953"
        }
    },
});