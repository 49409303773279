import {createSlice} from '@reduxjs/toolkit';

const symbolSlice = createSlice({
  name: 'symbol',
  initialState: {
    list: [],
    favoriteList: [],
    loading: false,
    marketWatch: {},
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSymbolList: (state, action) => {
      state.list = action.payload;
    },
    setFavoriteList: (state, action) => {
      state.favoriteList = action.payload;
    },
    addToFavorite: (state, action) => {
      state.favoriteList.push(action.payload);
    },
    removeToFavorite: (state, action) => {
      state.favoriteList = state.favoriteList.filter(
        item => item._id !== action.payload,
      );
    },
    setMarketWatch: (state, action) => {
      state.marketWatch = action.payload;
    },
    addMarketWatch: (state, action) => {
      state.marketWatch[action.payload.symbol] = action.payload;
    }
  },
});

export const {
  setSymbolList,
  setFavoriteList,
  addToFavorite,
  removeToFavorite,
  setLoading,
  setMarketWatch,
  addMarketWatch
} = symbolSlice.actions;

export default symbolSlice.reducer;
