import axios from 'axios'; // Assuming you're using axios for API calls

export async function getAllBrokers() {
    try {
        const response = await axios.get('https://root.edge-server.com/api/broker');
        return response;
    } catch (error) {
        // Handle errors gracefully
        console.error('Error fetching brokers:', error);
        throw error; // Re-throw the error for potential handling in calling code
    }
}

export async function externalLogin (data) {
    try {
        const response = await axios.post(`${data.broker}/api/user/guest/login`, { login: data.login, password: data.password });
        return response;
    } catch (error) {
        console.error('Error logging in:', error);
    }
}

export const deactiveAccount = async (data) => {
    try {
        return await axios.get(`${data.broker}/api/user/deactive?login=${data.login}`)
    } catch (error) { }
}