import { createSlice } from '@reduxjs/toolkit';

const symbolChangeSlice = createSlice({
    name: 'symbolChange',
    initialState: {
        currency: "EURUSD",
        currency2: "EURAUD",
        currency3: "GBPUSD",
        currency4: "EURGBP",
    },
    reducers: {
        setCurrencyChange: (state, action) => {
            state.currency = action.payload;
        },
        setCurrencyChange2: (state, action) => {
            state.currency2 = action.payload;
        },
        setCurrencyChange3: (state, action) => {
            state.currency3 = action.payload;
        },
        setCurrencyChange4: (state, action) => {
            state.currency4 = action.payload;
        },

    },
});

export const { setCurrencyChange, setCurrencyChange2, setCurrencyChange3, setCurrencyChange4 } = symbolChangeSlice.actions;

export default symbolChangeSlice.reducer;
