import {createSlice} from '@reduxjs/toolkit';

const priceSlice = createSlice({
  name: 'price',
  initialState: {
    currentPrice: {},
  },
  reducers: {
    setCurrentPrice: (state, action) => {
      state.currentPrice[action.payload.currency] = action.payload.data;
    },
    resetCurrentPrice: state => {
      state.currentPrice = {};
    },
  },
});

export const {setCurrentPrice, resetCurrentPrice} = priceSlice.actions;

export default priceSlice.reducer;
