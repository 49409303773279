import { createSlice } from "@reduxjs/toolkit";

const orderConfirmationSlice = createSlice({
    name: "confirmation",
    initialState: {
        orderConfirmDialog: JSON.parse(localStorage.getItem("dealsConfirmDialog")) || false,
        orderCloseModal: JSON.parse(localStorage.getItem("orderCloseModal")) || false,
        allOrderCloseModal: JSON.parse(localStorage.getItem("allOrderCloseModal")) || false,
    },
    reducers: {
        setOrderConfirmDialogs: (state, action) => {
            state.orderConfirmDialog = action.payload;
        },
        setOrderCloseConfirmModal: (state, action) => {
            state.orderCloseModal = action.payload;
        },
        setAllOrderCloseConfirmModal: (state, action) => {
            state.allOrderCloseModal = action.payload;
        },
    },
});

export const { setOrderConfirmDialogs, setOrderCloseConfirmModal, setAllOrderCloseConfirmModal } = orderConfirmationSlice.actions;

export default orderConfirmationSlice.reducer;