import { Box, Button, Card, CardActions, CardContent, TextField, Typography, Autocomplete, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { externalLogin, getAllBrokers } from "server/broker";
import IMG from "assets/images/6.jpg"
import { enqueueSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { deactiveAccount } from "server/broker";
const AccountDeactivate = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        broker: "",
        login: "",
        password: "",
    });
    const [brokers, setBrokers] = useState([]);

    const getBrokers = async () => {
        const res = await getAllBrokers();
        if (res.data?.success) {
            setBrokers(res.data.data);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleBrokerChange = (event, value) => {
        if (value) {
            setFormValues({
                ...formValues,
                broker: value.server,
            });
        } else {
            setFormValues({
                ...formValues,
                broker: "",
            });
        }
    };

    const handleSubmit = async (e) => {
        try {
            setLoading(true);
            e.preventDefault();
            if (!formValues.broker || !formValues.login || !formValues.password) {
                enqueueSnackbar(
                    <Box sx={{ margin: 0, padding: 0 }}>
                        <Typography sx={{ margin: 0, padding: 0, fontSize: 12, fontWeight: "900" }}>Error</Typography>
                        <Typography sx={{ margin: 0, padding: 0, fontSize: 12, fontWeight: "600" }}>
                            Please fill all the fields.
                        </Typography>
                    </Box>,
                    {
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        },
                        style: { backgroundColor: "#f44336", color: "#ffffff", marginTop: "30px" },
                        autoHideDuration: 4000,
                        action: (key) => (
                            <Box component={"span"} sx={{ position: "relative", cursor: "pointer" }}>
                                <CloseIcon sx={{ position: "absolute", top: -13, right: -2, color: "#ffffff", fontWeight: "700" }} fontSize="small"  />
                            </Box>
                        ),
                    }
                );
                setLoading(false);
                return;
            } else {
                const loginRes = await externalLogin(formValues);
                if (loginRes?.data?.success) {
                    const res = await deactiveAccount(formValues);
                    if (!res.data?.error) {
                        setLoading(false);
                        enqueueSnackbar(
                            <Box sx={{ margin: 0, padding: 0 }}>
                                <Typography sx={{ margin: 0, padding: 0, fontSize: 12, fontWeight: "900" }}>Success</Typography>
                                <Typography sx={{ margin: 0, padding: 0, fontSize: 12, fontWeight: "600" }}>
                                    Your account deactivate successfully.
                                </Typography>
                            </Box>,
                            {
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "right",
                                },
                                style: { backgroundColor: "#4caf50", color: "#ffffff", marginTop: "30px" },
                                autoHideDuration: 4000,
                                action: (key) => (
                                    <Box component={"span"} sx={{ position: "relative", cursor: "pointer" }}>
                                        <CloseIcon sx={{ position: "absolute", top: -13, right: -2, color: "#ffffff", fontWeight: "700" }} fontSize="small" />
                                    </Box>
                                ),
                            }
                        );
                    } else {
                        setLoading(false);
                        enqueueSnackbar(
                            <Box sx={{ margin: 0, padding: 0 }}>
                                <Typography sx={{ margin: 0, padding: 0, fontSize: 12, fontWeight: "900" }}>Error</Typography>
                                <Typography sx={{ margin: 0, padding: 0, fontSize: 12, fontWeight: "600" }}>
                                    {res.data?.error?.Description}
                                </Typography>
                            </Box>,
                            {
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "right",
                                },
                                style: { backgroundColor: "#f44336", color: "#ffffff", marginTop: "30px" },
                                autoHideDuration: 4000,
                                action: (key) => (
                                    <Box component={"span"} sx={{ position: "relative", cursor: "pointer" }}>
                                        <CloseIcon sx={{ position: "absolute", top: -13, right: -2, color: "#ffffff", fontWeight: "700" }} fontSize="small" />
                                    </Box>
                                ),
                            }
                        );
                    }
                } else {
                    setLoading(false);
                    enqueueSnackbar(
                        <Box sx={{ margin: 0, padding: 0 }}>
                            <Typography sx={{ margin: 0, padding: 0, fontSize: 12, fontWeight: "900" }}>Error</Typography>
                            <Typography sx={{ margin: 0, padding: 0, fontSize: 12, fontWeight: "600" }}>
                                Did not match any records.
                            </Typography>
                        </Box>,
                        {
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "right",
                            },
                            style: { backgroundColor: "#f44336", color: "#ffffff", marginTop: "30px" },
                            autoHideDuration: 4000,
                            action: (key) => (
                                <Box component={"span"} sx={{ position: "relative", cursor: "pointer" }}>
                                    <CloseIcon sx={{ position: "absolute", top: -13, right: -2, color: "#ffffff", fontWeight: "700" }} fontSize="small" />
                                </Box>
                            ),
                        }
                    );
                }

            }
        } catch (error) {
            setLoading(false);
            console.log("🚀 ~ handleSubmit ~ error:", error)
        }
    };

    useEffect(() => {
        getBrokers();
    }, []);

    return (
        <Box
            sx={{
                height: '100vh',
                width: '100%',
                backgroundImage: `url(${IMG})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
            className='flex justify-center items-center'
        >
            <Card sx={{ minWidth: 275, width: 400, bgcolor: "#020000" }}>
                <CardContent className="text-center">
                    <Typography variant="h5" component="div" gutterBottom sx={{ color: "var(--color-white)" }}>
                        Deactivate Account
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ color: "var(--text-color)" }}>
                        Please fill out the form below to deactivate your account.
                    </Typography>
                </CardContent>
                <CardActions>
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2 }}>
                            <Autocomplete
                                options={brokers}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} label="Broker" variant="outlined" />}
                                onChange={handleBrokerChange}
                                value={brokers.find(broker => broker.server === formValues.broker) || null}
                                fullWidth
                                sx={{
                                    "& .MuiInput-root": {
                                        color: "var(--color-black)",
                                        fontSize: "16px",
                                        fontWeight: 500,
                                        border: '1px solid var(--text-color)'
                                    },
                                    "& label": {
                                        fontSize: "16px",
                                        color: "var(--color-white)",
                                        fontWeight: 500,
                                    },
                                    "& label.Mui-focused": {
                                        color: "var(--color-white)",
                                    },
                                }}
                            />
                            <TextField
                                label="Login"
                                variant="outlined"
                                name="login"
                                value={formValues.login}
                                onChange={handleInputChange}
                                fullWidth
                                sx={{
                                    "& .MuiInput-root": {
                                        color: "var(--color-black)",
                                        fontSize: "16px",
                                        fontWeight: 500,
                                        border: '1px solid var(--text-color)'
                                    },
                                    "& label": {
                                        fontSize: "16px",
                                        color: "var(--color-white)",
                                        fontWeight: 500,
                                    },
                                    "& label.Mui-focused": {
                                        color: "var(--color-white)",
                                    },
                                    
                                }}
                            />
                            <TextField
                                label="Password"
                                variant="outlined"
                                name="password"
                                type="password"
                                value={formValues.password}
                                onChange={handleInputChange}
                                fullWidth
                                sx={{
                                    "& .MuiInput-root": {
                                        color: "var(--color-black)",
                                        fontSize: "16px",
                                        fontWeight: 500,
                                        border: '1px solid var(--text-color)'
                                    },
                                    "& label": {
                                        fontSize: "16px",
                                        color: "var(--color-white)",
                                        fontWeight: 500,
                                    },
                                    "& label.Mui-focused": {
                                        color: "var(--color-white)",
                                    },
                                    
                                }}
                            />
                            <Button type="submit" variant="contained" color="error" >
                               {loading ? <CircularProgress color="success" size={25} />  : "Deactivate Account"}
                            </Button>
                        </Box>
                    </form>
                </CardActions>
            </Card>
        </Box>
    );
};

export default AccountDeactivate;
