import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../redux/features/counter/counterSlice";
import authReducer, { loggedIn } from "./features/auth/authSlice";
import symbolReducer, { setFavoriteList } from "./features/symbolSlice/symbolSlice";
import orderReducer from "./features/order/orderSlice";
import chartReducer, { setCurrentChartLayout } from "./features/chart/chartSlice";
import priceReducer from "./features/price/priceSlice";
import orderConfirmationSlice from "./features/order/orderConfirmationSlice"
import symbolChange from "./features/symbolChange/symbolChangeSlice";
import axios from "axios";
import newsSlice, { setCategories } from "./features/news/newsSlice";
import layoutSlice, { setInitialSidebar } from "./features/layouts/layoutSlice";
import applicationSlice, { setThemeMode } from "./features/application/applicationSlice";
import tabSlice from "./features/tabSlice/tabSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    symbol: symbolReducer,
    order: orderReducer,
    chart: chartReducer,
    price: priceReducer,
    news: newsSlice,
    confirmation: orderConfirmationSlice,
    tabChange: tabSlice,
    symbolChange: symbolChange,
    layout: layoutSlice,
    application: applicationSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Load data from local storage
const loadDataFromStorage = async () => {
  try {
    const user = await localStorage.getItem('user');
    if (user) {
      store.dispatch(loggedIn(JSON.parse(user)));
    }
    const favoriteList = await localStorage.getItem('favorites');
    if (favoriteList) {
      const newFavData = JSON.parse(favoriteList);
      store.dispatch(setFavoriteList(newFavData));
    }
    const sidebar = await localStorage.getItem("sidebarOpen");
    if (JSON.parse(sidebar)) {
      store.dispatch(setInitialSidebar());
    }

    const currentChartLayout = await localStorage.getItem('currentChartLayout');
    if (currentChartLayout) {
      store.dispatch(setCurrentChartLayout(JSON.parse(currentChartLayout)));
    }

    const themeMode = await localStorage.getItem('themeMode');
    if (themeMode) {
      store.dispatch(setThemeMode(themeMode));
    }
  } catch (error) {
    console.log('Error loading data from store.js:', error);
  }
};

// Load data from Server
const loadDataFromServer = async () => {
  try {
    axios.get(`${process.env.REACT_APP_API_POST_API_URL}/categories`).then(res => {
      store.dispatch(
        setCategories([
          { name: 'News Feed', id: '0' },
          { name: 'Daily News', id: '00' },
          { name: 'Market Analysis', id: '000' },
          ...res.data.map(i => ({ name: i.name, id: i.id })),
        ]),
      );
    });
  } catch (error) {
    console.log('Error loading data from store.js:', error);
  }

}

loadDataFromServer();
loadDataFromStorage();


