import React, { memo, useState, useEffect } from "react";
import styles from "./lock-screen.module.css";
import companyLogo from "../../../assets/Auth/icon.png";
import { Box, Button, CircularProgress, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import Clock from "../Clock/Clock";
import { useFormik } from "formik";
import { login } from "server/auth";
import { useDispatch } from "react-redux";
import { signin } from "redux/features/auth/authSlice";
import CloseIcon from "@mui/icons-material/Close";
import { closeSnackbar, useSnackbar } from "notistack";
import { KeyboardBackspace, Visibility, VisibilityOff } from "@mui/icons-material";
import * as yup from "yup";
import message from "enum/message";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object().shape({
  login: yup.string("Enter your login").required(message.LOGIN_REQUIRED),
  password: yup.string("Enter your password").min(6, message.MIN_PASSWORD).required(message.PASSWORD_REQUIRED),
});

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};

const LockScreen = () => {
  const [elapsedTime, setElapsedTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lock_login = localStorage.getItem("lock_login");

  useEffect(() => {
    const storedElapsedTime = sessionStorage.getItem("elapsedTime");

    if (storedElapsedTime) {
      setElapsedTime(parseInt(storedElapsedTime, 10));
    }

    if (!lock_login) {
      navigate("/auth");
    }

    const timer = setInterval(() => {
      setElapsedTime((prevTime) => {
        const newTime = prevTime + 1;
        sessionStorage.setItem("elapsedTime", newTime.toString());
        return newTime;
      });
    }, 1000);

    // Clear the timer when the component unmounts
    return () => {
      clearInterval(timer);
    };
  }, [lock_login, navigate]);

  // Formik
  const formik = useFormik({
    initialValues: {
      login: lock_login || "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const result = await login({
        login: values.login,
        password: values.password,
      });

      if (!result.data?.error) {
        localStorage.removeItem("lock_login");
        sessionStorage.removeItem("elapsedTime");
        setElapsedTime(0);
        navigate("/");
        dispatch(signin({ ...result.data?.data?.info }));
        setLoading(false);
      } else {
        enqueueSnackbar(
          <Box sx={{ margin: 0, padding: 0 }}>
            <Typography sx={{ margin: 0, padding: 0, fontSize: 12, fontWeight: "900" }}>{result.data?.error?.Description}</Typography>
          </Box>,
          {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            style: { color: "#ecfbf4" },
            autoHideDuration: 8000,
            action: (key) => (
              <Box component={"span"} sx={{ position: "relative", cursor: "pointer" }}>
                <CloseIcon sx={{ position: "absolute", top: -13, right: -2, color: "#ecfbf4", fontWeight: "700" }} fontSize="small" onClick={() => closeSnackbar(key)} />
              </Box>
            ),
          }
        );
        setLoading(false);
      }
    },
  });

  return (
    <div className={`${styles.banner} ${styles["background-spin"]}`}>
      <div style={{ minHeight: "320px" }} className="w-[350px] bg-[var(--bg-color-0)] rounded-lg">
        <div className="flex justify-center items-center mt-8">
          <img className="w-[60px] h-[60px]" src={companyLogo} alt="company logo" />
          <h4 className="text-3xl !m-0 !p-0 !ml-1 text-[var(--color-white)]">Edge Trader</h4>
        </div>
        <div className="flex justify-center my-5" style={{ fontSize: "14px" }}>
          <span className="text-[var(--color-yellow)] pl-1">{formatTime(elapsedTime)}</span>
        </div>
        <form className="px-8 pt-0 pb-4" onSubmit={formik.handleSubmit}>
          <div className="flex justify-center -mb-5">
            <div className="w-12 h-12 rounded-full text-center flex items-center justify-center" style={{ border: "1px solid var(--color-tertiary)" }}>
              <Typography sx={{ fontSize: "12px", color: "var(--color-green)" }}>{lock_login}</Typography>
            </div>
          </div>
          <div>
            <TextField
              label="Login"
              variant="standard"
              type="text"
              fullWidth
              size="medium"
              id="login"
              name="login"
              value={formik.values.login}
              onChange={formik.handleChange}
              error={formik.touched.login && Boolean(formik.errors.login)}
              helperText={formik.touched.login && formik.errors.login}
              sx={{
                display: "none",
                "& .MuiInput-root": {
                  color: "var(--color-white)",
                  fontSize: "16px",
                  fontWeight: 500,
                },
                "& label": {
                  fontSize: "16px",
                  color: "var(--text-color)",
                  fontWeight: 500,
                },
                "& label.Mui-focused": {
                  color: "var(--color-green)",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "var(--text-color)",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "var(--text-color)",
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottomColor: "none",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "var(--color-green)",
                },
              }}
            />
          </div>
          <div className="mt-3 ">
            <TextField
              label="Password"
              variant="standard"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                      {showPassword ? <VisibilityOff sx={{ color: "var(--color-white)" }} /> : <Visibility sx={{ color: "var(--color-white)" }} />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              size="medium"
              id="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              sx={{
                "& .MuiInput-root": {
                  color: "var(--color-white)",
                  fontSize: "16px",
                  fontWeight: 500,
                },
                "& label": {
                  fontSize: "16px",
                  color: "var(--text-color)",
                  fontWeight: 500,
                },
                "& label.Mui-focused": {
                  color: "var(--color-green)",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "var(--text-color)",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "var(--text-color)",
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottomColor: "none",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "var(--color-green)",
                },
              }}
            />
          </div>

          <div className="mt-10">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                padding: "8px 12px",
                backgroundColor: "#00b276",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#00a46d",
                },
              }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : "Unlock"}
            </Button>
          </div>
        </form>
        <div className="flex justify-center pb-4">
          <Button startIcon={<KeyboardBackspace style={{ color: "var(--color-green)" }} />}>
            <a href="/auth" style={{ color: "var(--color-green)" }}>
              Back to Login
            </a>
          </Button>
        </div>
      </div>
      {/* Clock Analog */}
      <Clock />
    </div>
  );
};

export default memo(LockScreen);
